* {
  font-family: Arial, Helvetica, sans-serif; }

.main {
  margin: 0;
  padding: 0;
  background-color: black; }

.header-container {
  display: flex;
  position: absolute;
  justify-content: center;
  height: 75px;
  width: 100%; }
  .header-container .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    width: 80%; }
    .header-container .header .nav-item {
      cursor: pointer;
      padding: 10px;
      margin: 0 10px;
      border: 1px solid #ffffff;
      border-radius: 5px;
      color: #ffffff; }
      .header-container .header .nav-item:hover {
        background-color: #ffffff;
        color: #000000; }
    .header-container .header .logo {
      margin-right: auto;
      max-height: 100%;
      margin-left: 20px; }

.banner {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);
  width: 100%;
  overflow: hidden; }
  .banner .video-filter {
    height: calc(100vh - 60px);
    position: absolute;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1; }
  .banner .video-container {
    display: flex;
    position: absolute;
    width: 100%;
    height: calc(100vh - 60px);
    z-index: -99;
    overflow: hidden; }
    .banner .video-container .video {
      position: relative;
      object-fit: cover;
      width: 100%;
      height: 100%; }
  .banner .banner-message {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 100px; }
    .banner .banner-message img {
      max-width: 500px; }

.navbar-container {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #ecf0f1;
  position: sticky;
  top: 0;
  background-color: #FFFFFF; }
  .navbar-container .navbar {
    display: flex;
    height: 60px;
    width: 80%;
    align-items: center;
    justify-content: center; }
    .navbar-container .navbar a.navbar-item {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 150px;
      color: #000000;
      text-decoration: none; }
      .navbar-container .navbar a.navbar-item:hover {
        color: #7f8c8d; }

.content {
  width: 80%;
  margin: 0 auto;
  justify-content: center; }
  .content .row {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    margin: 50px 0; }
    .content .row.align-top {
      align-items: initial; }
    .content .row.column {
      flex-direction: column; }
    .content .row.left {
      align-items: flex-start; }
    @media (max-width: 768px) {
      .content .row {
        flex-direction: column; }
        .content .row div {
          width: 100% !important; } }
    .content .row .small {
      width: 25%; }
    .content .row .big {
      width: 55%; }

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 50px; }
  .card-container .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    text-decoration: none;
    color: #000;
    width: 160px;
    height: 175px;
    border: 1px solid #bdc3c7;
    margin: 10px; }
    .card-container .card * {
      text-align: center; }
    .card-container .card img {
      height: 100px;
      width: 100%;
      object-fit: cover;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px; }
    .card-container .card div {
      display: flex;
      height: 100%;
      align-items: center;
      font-size: 20px; }

img {
  max-width: 100%; }

.teams-page {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .teams-page .row {
    max-height: 1080px; }
    .teams-page .row .small {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%; }
    .teams-page .row .big {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center; }
      .teams-page .row .big h1 {
        text-align: center; }
      .teams-page .row .big p {
        margin-top: 0;
        max-width: 600px; }
    .teams-page .row img {
      border-radius: 50%;
      height: 250px;
      width: 250px;
      object-fit: cover; }

.sponsors-page {
  display: flex;
  justify-content: space-evenly; }
  .sponsors-page img {
    width: 25%; }

.contact-exec-page .exec-tiles {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column; }
  .contact-exec-page .exec-tiles > div {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    margin: 10px 0;
    border-top: 1px solid black; }
    .contact-exec-page .exec-tiles > div h2 {
      margin: 15px 0; }
    .contact-exec-page .exec-tiles > div p {
      margin: 5px 0; }
